<template>
    <section>
        <Header/>
        <section class="main">
          <Feedback />
        <section>

          <section class="topBar" style="background-color:#1B355E;margin-top:0;color:white;text-shadow: 0px 3px 6px #00000091;margin-bottom:0px;">
            <h1 class="about_maintitle">{{ content[this.setLang].about_title}}</h1>
          </section>
          
          <section style="background-color:#EAEAEB;margin-top:-10px;padding:40px 0px 30px 0px;">
            <div class="introText" style="background-color:#EAEAEB;margin-top:0px;">
                <p style="background-color:#EAEAEB;margin-top:0px;" v-html="content[this.setLang].intro_content">
                 </p>
            </div>
          </section>

          <section>

            <div class="introText">

              <h2><b>{{content[this.setLang].ocrc_title}}</b></h2>

              <p>{{content[this.setLang].ocrc_content}}</p>

              

              <h2><b>{{content[this.setLang].ccohs_title}}</b></h2>

              <p>{{content[this.setLang].ccohs_content}}</p>

              
            </div>
          </section>

        </section>
        </section>
        <Footer/>
    </section>
</template>

<script>
// Imports
import Vue from 'vue'
import VueHead from 'vue-head'
import Header from './Header.vue'
import Footer from './Footer.vue'
import Feedback from '../components/feedback.vue'

Vue.use(VueHead)

export default {
  components: {
    Header,
    Footer,
    Feedback
  },
  data() {
    return {
     content:{
       en: {
        about_title : 'About Us',
        intro_content: 'This project is a partnership between the <a class="aboutLink font-weight-bold" href="https://www.occupationalcancer.ca/">Occupational Cancer Research Centre</a> (OCRC) and the <a class="aboutLink font-weight-bold" href="https://www.ccohs.ca/">Canadian Centre for Occupational Health and Safety</a> (CCOHS). In 2016, the OCRC created the Occupational Disease Surveillance System (ODSS) to identify patterns and monitor trends in work-related disease. It is now part of the <a class="aboutLink font-weight-bold" href="https://www.odsp-ocrc.ca/">Occupational Disease Surveillance Program</a> funded by the Ontario Ministries of Health (MOH) and Labour Training and Skills Development (MLTSD). This website aims to disseminate information generated by the ODSS and other relevant data sources to the broader health and safety community to support occupational disease prevention efforts. This project is funded by a grant provided by the Workplace Safety and Insurance Board (WSIB) of Ontario. The provision of grant support by WSIB does not in any way infer or imply endorsement of the content by the WSIB.',
        ocrc_title: 'Occupational Cancer Research Centre',
        ocrc_content: 'The OCRC was established in 2009 as an applied research centre. It has three broad categories of research: surveillance to identify workers with the highest risk; epidemiologic research to better understand the occupational causes of cancer; and prevention research to identify the most effective interventions to reduce exposure. The goal of all OCRC research projects is to generate evidence-based knowledge that can be used to reduce occupational exposures. Because of its focus on exposure and prevention, the OCRC has gradually transitioned to covering a broad range of adverse health outcomes related to work. The Centre is based in Toronto at Ontario Health, and receives additional core funding from Ontario’s MLTSD and the Canadian Cancer Society.',
        ccohs_title: 'Canadian Centre for Occupational Health and Safety',
        ccohs_content: 'For more than 40 years, the Canadian Centre for Occupational Health and Safety has advocated for the total physical and psychological health and well-being of working people in Canada. As Canada’s national resource dedicated to advancing workplace health and safety, CCOHS develops credible information, education and innovative solutions for use by employers, workers, and stakeholders to create positive change in workplaces. CCOHS provides a national perspective while supporting the efforts of partner organizations across the country on current issues such as occupational disease prevention. CCOHS hosts, develops, and maintains the Ontario Occupational Disease Statistics website.'
       },
       fr: {
        about_title : 'À propos de nous',
        intro_content: 'Ce projet est un partenariat entre le <a class="aboutLink font-weight-bold" href="https://www.occupationalcancer.ca/">Centre de recherche sur le cancer professionnel</a> (CRCP) et le <a class="aboutLink font-weight-bold" href="https://www.cchst.ca/">Centre canadien d’hygiène et de sécurité au travail</a> (CCHST). En 2016, le CRCP a créé le Système de surveillance des maladies professionnelles (SSMP) pour déterminer les cas types et surveiller les tendances des maladies professionnelles. Ce système fait maintenant partie du <a class="aboutLink font-weight-bold" href="https://www.odsp-ocrc.ca/">Programme de surveillance des maladies professionnelles</a>, financé par le ministère de la Santé et le ministère du Travail, de la Formation et du Développement des compétences (MTFDC) de l’Ontario. Ce site Web vise à diffuser l’information produite par le SSMP et d’autres sources de données pertinentes à l’ensemble de la communauté de la santé et de la sécurité afin d’appuyer les efforts de prévention des maladies professionnelles. Ce projet est financé par le programme de subventions de la Commission de la sécurité et de l’assurance des travailleurs, numéro de subvention DEME2019. ',
        ocrc_title: 'Centre de recherche sur le cancer professionnel ',
        ocrc_content: 'Le CRCP a été établi en 2009 à titre de centre de recherche appliquée. Il comporte trois catégories élargies de recherche : la recherche sur la surveillance afin d’identifier les travailleurs les plus exposés à un risque de cancer; la recherche épidémiologique pour mieux comprendre les causes du cancer professionnel; et la recherche sur la prévention pour déterminer les interventions les plus efficaces pour réduire l’exposition. L’objectif des projets de recherche du CRCP consiste à créer des connaissances fondées sur des données probantes qui peuvent être mises à profit pour réduire les expositions professionnelles. Étant donné que le CRCP se préoccupe surtout d’exposition et de prévention au travail, il a graduellement commencé à couvrir une vaste gamme d’effets indésirables sur la santé issus de l’exposition en milieu de travail. Le Centre est situé à Toronto au sein de l’organisme Santé Ontario et reçoit un financement de base supplémentaire du MTFDC de l’Ontario et de la Société canadienne du cancer.',
        ccohs_title: "Centre canadien d'hygiène et de sécurité au travail ",
        ccohs_content: "Depuis plus de 40 ans, le Centre canadien d'hygiène et de sécurité au travail milite pour la santé et le bien-être physique et psychologique des travailleurs du Canada. À titre de ressource nationale du Canada vouée à la promotion de la santé et de la sécurité au travail, le CCHST élabore de l’information crédible, de la formation et des solutions novatrices destinées aux employeurs, aux travailleurs et aux intervenants pour créer des changements positifs dans les milieux de travail. Il offre une perspective nationale tout en appuyant les efforts des organisations partenaires à l’échelle du pays sur des enjeux actuels comme la prévention des maladies professionnelles. Il héberge, élabore et tient à jour le site Web qui contient les statistiques sur les maladies professionnelles en Ontario. "
       }
     }
    }
  },
  head: {
    title:{
      inner: 'About Us | Ontario Occupational Disease Statistics'
    }
  },
  computed:{
    setLang : function () {
      var lang = (this.$route.query.locale) ? this.$route.query.locale : 'en';
      return lang;
    }
  }
}
</script>


<style scoped>
h2 {
  font-size:28px;
}

p {
  font-size:0.7em;
  padding-bottom:40px;
}
</style>